<template>
    <Navbar />
    <div>
        <GizlilikPolitikasi />
    </div>
</template>
<script>

import Navbar from "../../navbar.vue";
import GizlilikPolitikasi from "./template_GizlilikPolitikasi.vue"
export default {
    data() {
        return {}
    },
    components: {
        Navbar,
        GizlilikPolitikasi
    }
}
</script>